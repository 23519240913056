export const parsingOptions = (selectData: Array<{ value: string | number; label: string }>) => {
    const data = selectData;
    const options = data.map((d) => ({
        value: d.value,
        label: d.label,
    }));
    return options;
};

export const removeCommon = async (options: any, optionsSecond: any, value: string) => {
    for (var i = options.length - 1; i >= 0; i--) {
        for (var j = 0; j < optionsSecond.length; j++) {
            if (options[i] && options[i][value] === optionsSecond[j][value]) {
                options.splice(i, 1);
            }
        }
    }
    return options;
};

export const deleteSpace = (data: string) => {
    return data.replace(/%20/g, ' ');
};

export const timeoutDelay = (delay: number) => {
    return new Promise((res) => setTimeout(res, delay));
};

export const getText = (html: string) => {
    var divContainer = document.createElement('div');
    divContainer.innerHTML = html;
    return divContainer.textContent || divContainer.innerText || '';
};

export const uniqueArrayObject = (data: any, name: string) => {
    data.slice()
        .reverse()
        .filter((v: { [x: string]: any }, i: any, a: { [x: string]: any }[]) => a.findIndex((t: { [x: string]: any }) => t[name] === v[name]) === i)
        .reverse();
};

export const dateParamTahunBulanHari = (param: Date) => {
    return [param.getFullYear(), ('0' + (param.getMonth() + 1)).slice(-2), ('0' + param.getDate()).slice(-2)].join('-');
};

export const dateParamTahunBulan = (param: Date) => {
    return [param.getFullYear(), ('0' + (param.getMonth() + 1)).slice(-2)].join('-');
};

export const dateParamBulan = (param: Date) => {
    return ('0' + (param.getMonth() + 1)).slice(-2);
};
export function precise_round_custom(num: number, decimals: number) {
    var t = Math.pow(10, decimals);
    return (Math.round(num * t + (decimals > 0 ? 1 : 0) * (Math.sign(num) * (10 / Math.pow(100, decimals)))) / t).toFixed(decimals);
}

export const returnPercentage = (num: number, total: number, decimal: number) => {
    let ret = num === 0 ? 0 : Number(((100 * num) / total).toFixed(decimal));
    return ret;
};
