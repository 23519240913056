import { getRoleForApp } from '../../../utility/cookies/securing';
import { PesanParam } from '../../../utility/interface/PesanIntefaces';
import { GetAPI, PostAPI } from '../../globalCallAPI';

let role = getRoleForApp('sess');

// ===== List Antrian Pesan ====
export let fetchListPesan = async (bearer: string, page: number, key: string, status_pesan: number) => {
    const a = `${role}/pesan_notif/list_pesan`;
    // const param = '/' + page + '/' + key;
    const param = { key: key, page: page, status_pesan: status_pesan };
    let s = await GetAPI(a, param, bearer);
    return s;
};

export let fetchTanggapanPesan = async (bearer: string, param: string | string[] | undefined | null, page: number) => {
    // const param = '/' + page + '/' + key;
    let a = `${role}/pesan_notif/list_tanggapan_pesan/${param}`;
    const _param = { page: page };
    let s = await GetAPI(a, _param, bearer);
    return s;
};

export const fetchKirimTanggapanPesan = async (bearer: string, param: PesanParam) => {
    const a = `${role}/pesan_notif/kirim_tanggapan_pesan`;
    let s = await PostAPI(a, param, bearer);
    return s;
};

export let fetchDetailNotif = async (bearer: string, param: string | string[] | undefined | null) => {
    const a = `${role}/pesan_notif/detail/${param}`;
    let s = await GetAPI(a, {}, bearer);
    return s;
};

export let fetchListNotifikasi = async (bearer: string, page: number, key: string) => {
    const a = `${role}/pesan_notif/list_notifikasi`;
    const param = { key: key, page: page };
    let s = await GetAPI(a, param, bearer);
    return s;
};
export let fetchListPesanOnly = async (bearer: string, page: number, key: string) => {
    const a = `${role}/pesan_notif/list_pesan`;
    // const param = '/' + page + '/' + key;
    const param = { key: key, page: page };
    let s = await GetAPI(a, param, bearer);
    return s;
};

export let fetchNotifStatusBar = async (bearer: string) => {
    let s = await GetAPI(`${role}/pesan_notif/jumlah_pesan_notif`, {}, bearer);
    return s;
};
