import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import axios from 'axios';
import { config } from '../../../authServices/firebase/firebaseConfig';
import SecureLS from 'secure-ls';
import { gotoURL } from '../../../utility/component/gotoUrl';

// Init firebase if not already initialized
if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

let firebaseAuth = firebase.auth();

// const initAuth0 = new auth0.WebAuth(configAuth)

export function submitLoginWithFireBase(email: string, password: string, remember: any) {
    return (
        dispatch: (arg0: {
            type: string;
            payload:
                | {
                      email: string | null;
                      name: string | null;
                      isSignedIn: boolean;
                      loggedInWith: string;
                  }
                | {
                      email: any;
                      name: string | null;
                      isSignedIn: boolean;
                      remember: boolean;
                      loggedInWith: string;
                  };
        }) => void,
    ) => {
        let userEmail: string | null = null,
            loggedIn = false;
        firebaseAuth
            .signInWithEmailAndPassword(email, password)
            .then((result) => {
                firebaseAuth.onAuthStateChanged((user) => {
                    result.user!.updateProfile({
                        displayName: 'Admin',
                    });
                    let name = result.user!.displayName;
                    if (user) {
                        userEmail = user.email;
                        loggedIn = true;
                        dispatch({
                            type: 'LOGIN_WITH_EMAIL',
                            payload: {
                                email: userEmail,
                                name,
                                isSignedIn: loggedIn,
                                loggedInWith: 'firebase',
                            },
                        });
                    }
                    if (user && remember) {
                        firebase
                            .auth()
                            .setPersistence(firebase.auth.Auth.Persistence.SESSION)
                            .then(() => {
                                dispatch({
                                    type: 'LOGIN_WITH_EMAIL',
                                    payload: {
                                        email: userEmail,
                                        name,
                                        isSignedIn: loggedIn,
                                        remember: true,
                                        loggedInWith: 'firebase',
                                    },
                                });
                            });
                    }
                    gotoURL('/');
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };
}

export function loginWithJWT(user: { email: any; password: any }) {
    return (ret: { msg: string; status: number }) => {
        // process.env.API_KEY_PENGELOLA;
        // const result = require("dotenv").config();

        // if (result.error) {
        //   throw result.error;
        // }
        // dotenv.config();
        // console.log(process.env.REACT_APP_PUBLIC_PATH);

        const apiHeaders = {
            // "api-key": process.env.REACT_APP_PUBLIC_PATH,
            'api-key': 'sDefe324b8mdK8perRTy0ZCg',
        };
        const apiUrl = 'http://satu.expl.web.id:9000/public/index.php/api/publik/auth/login';
        axios
            .post(
                apiUrl,
                {
                    email: user.email,
                    password: user.password,
                },
                { headers: apiHeaders },
            )
            .then((response) => {
                // console.log(response);
                const today = new Date().toLocaleString();
                var ls2 = new SecureLS({
                    encodingType: 'aes',
                    isCompression: true,
                    encryptionSecret: 'CGYPZlNcqfz0JrGywlF7Ok3S',
                });

                ls2.removeAll();

                ls2.set('sess', {
                    data: [today, response.data.result.token, today],
                });

                // var ls3 = new SecureLS();
                // var sss = ls3.get("sess1");

                // console.log(sss);

                // console.log("asdasdadadasd");
                // sessionStorage.setItem("sess", );
                if (response.data) {
                    // console.log(response);
                    // loggedInUser = response.data.user;

                    ret.msg = 'Pesan Berhasilasdas';
                    ret.status = 1;

                    // history.push("/dashboard_pengelola");
                }
            })
            .catch((err) => {
                ret.msg = 'Pesan Gagal';
                ret.status = 0;
            });
        // ret.msg = "Pesan Berhasil";
        // ret.status = 1;
        return ret;
    };
}

export const logoutWithJWT = () => {
    return (dispatch: (arg0: { type: string; payload: {} }) => void) => {
        var ls2 = new SecureLS({
            encodingType: 'aes',
            isCompression: true,
            encryptionSecret: 'CGYPZlNcqfz0JrGywlF7Ok3S',
        });
        ls2.removeAll();

        dispatch({ type: 'LOGOUT_WITH_JWT', payload: {} });
        gotoURL('/login');
    };
};

export const checkLoginJWT = () => {
    return (dispatch: () => void) => {
        var ls3 = new SecureLS({
            encodingType: 'aes',
            isCompression: true,
            encryptionSecret: 'CGYPZlNcqfz0JrGywlF7Ok3S',
        });
        var sss = ls3.get('sess');
        // console.log(sss);
        if (sss === '') {
            // console.log("data kosong");
            gotoURL('/login');
        }
    };
};

// export const logoutWithFirebase = (user: any) => {
//   return (dispatch: (arg0: { type: string; payload: {} }) => void) => {
//     dispatch({ type: "LOGOUT_WITH_FIREBASE", payload: {} });
//     history.push("/pages/login");
//   };
// };

export const changeRole = (role: any) => {
    return (dispatch: (arg0: { type: string; userRole: any }) => any) =>
        dispatch({ type: 'CHANGE_ROLE', userRole: role });
};

/* export function loginWithJWT(user: { email: any; password: any }) {
  return (
    dispatch: (arg0: {
      type: string;
      payload: { loggedInUser: any; loggedInWith: string };
    }) => void
  ) => {
    // process.env.API_KEY_PENGELOLA;
    // const result = require("dotenv").config();

    // if (result.error) {
    //   throw result.error;
    // }
    dotenv.config();
    //console.log(process.env.REACT_APP_PUBLIC_PATH);

    const apiHeaders = {
      // "api-key": process.env.REACT_APP_PUBLIC_PATH,
      "api-key": "sDefe324b8mdK8perRTy0ZCg",
    };
    const apiUrl = "api/publik/auth/login";
    axios
      .post(
        apiUrl,
        {
          email: user.email,
          password: user.password,
        },
        { headers: apiHeaders }
      )
      .then((response) => {
        // console.log(response);
        var loggedInUser;

        const today = new Date().toLocaleString();
        var ls2 = new SecureLS({
          encodingType: "aes",
          isCompression: true,
          encryptionSecret: "CGYPZlNcqfz0JrGywlF7Ok3S",
        });

        ls2.removeAll();

        ls2.set("sess", {
          data: [today, response.data.result.token, today],
        });

        // var ls3 = new SecureLS();
        // var sss = ls3.get("sess1");

        // console.log(sss);

        // console.log("asdasdadadasd");
        // sessionStorage.setItem("sess", );
        if (response.data) {
          loggedInUser = response.data.user;

          dispatch({
            type: "LOGIN_WITH_JWT",
            payload: { loggedInUser, loggedInWith: "jwt" },
          });

          history.push("/dashboard_pengelola");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
} */
