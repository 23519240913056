import SecureLS from 'secure-ls';

const ss_ = 'CGYPZlNcqfz0JrGywlF7Ok3S';
export const createCookie = (token: string | undefined, name: string, role: string | undefined, email: string | undefined) => {
    const today = new Date().toLocaleString();
    localStorage.clear();
    var ls2 = new SecureLS({
        encodingType: 'aes',
        isCompression: true,
        encryptionSecret: ss_,
    });
    ls2.set(name, {
        data: [today, token, role, email, today],
    });
};

export const checkCookie = (name: string) => {
    try {
        var ls3 = new SecureLS({
            encodingType: 'aes',
            isCompression: true,
            encryptionSecret: ss_,
        });
        let ses = ls3.get(name);
        if (ses === '') {
            return false;
        }

        // if (sss !== null || sss !== undefined) {
        return true;
        // }
        // return false;
    } catch (error) {
        return false;
    }
};

export const removeCookies = () => {
    localStorage.removeItem('_secure__ls__metadata');
    localStorage.removeItem('sess');
    // var ls4 = new SecureLS({
    //     encodingType: 'aes',
    //     isCompression: true,
    //     encryptionSecret: ss_,
    // });
    // ls4.removeAll();
};

export const getBearer = (name: string) => {
    var bearer: string;
    try {
        var ls5 = new SecureLS({
            encodingType: 'aes',
            isCompression: true,
            encryptionSecret: ss_,
        });
        let ret_ber = ls5.get(name);
        if (ret_ber.data.length === 5) {
            return ret_ber.data[1];
        } else {
            return (bearer = '');
        }
    } catch (err) {
        bearer = '';
    }
    return bearer;

    // try {
    //     var ls5 = new SecureLS({
    //         encodingType: 'aes',
    //         isCompression: true,
    //         encryptionSecret: 'CGYPZlNcqfz0JrGywlF7Ok3S',
    //     });
    //     var bearer = ls5.get('sess');
    //     return bearer;
    // } catch (error) {
    //     return '';
    // }
};

export const getRole = (name: string) => {
    var bearer: string;
    try {
        var ls6 = new SecureLS({
            encodingType: 'aes',
            isCompression: true,
            encryptionSecret: ss_,
        });
        let ret_ber = ls6.get(name);
        if (ret_ber.data.length === 5) {
            return ret_ber.data[2];
        } else {
            return (bearer = '');
        }
    } catch (err) {
        bearer = '';
    }
    return bearer;

    // try {
    //     var ls5 = new SecureLS({
    //         encodingType: 'aes',
    //         isCompression: true,
    //         encryptionSecret: 'CGYPZlNcqfz0JrGywlF7Ok3S',
    //     });
    //     var bearer = ls5.get('sess');
    //     return bearer;
    // } catch (error) {
    //     return '';
    // }
};

export const getRoleForApp = (name: string) => {
    let role_exist = getRole(name);
    let ret: string;
    ret = role_exist;
    if (role_exist === 'manager') {
        ret = 'admin';
    }else if (role_exist === 'public'){
        ret = 'publik';
    }
    return ret;
};

export const getEmail = (name: string) => {
    var bearer: string;
    try {
        var ls6 = new SecureLS({
            encodingType: 'aes',
            isCompression: true,
            encryptionSecret: ss_,
        });
        let ret_ber = ls6.get(name);
        if (ret_ber.data.length === 5) {
            return ret_ber.data[3];
        } else {
            return (bearer = '');
        }
    } catch (err) {
        bearer = '';
    }
    return bearer;

    // try {
    //     var ls5 = new SecureLS({
    //         encodingType: 'aes',
    //         isCompression: true,
    //         encryptionSecret: 'CGYPZlNcqfz0JrGywlF7Ok3S',
    //     });
    //     var bearer = ls5.get('sess');
    //     return bearer;
    // } catch (error) {
    //     return '';
    // }
};
