import React from 'react';
import * as Icon from 'react-feather';
const navigationConfig = [
    // {
    //     id: 'dashboardPengelola',
    //     title: 'Dashboard',
    //     type: 'item',
    //     icon: <Icon.Home size={20} />,
    //     permissions: ['manager'],
    //     navLink: '/dashboard_pengelola',
    // },
    {
        id: 'dashboardPetugasTerbitanBerdasarkanStatus',
        title: 'Dashb. Managerial',
        type: 'item',
        icon: <Icon.Home size={20} />,
        permissions: ['manager'],
        navLink: '/dashboard_managerial',
    },
    {
        id: 'dashboardPetugasTerbitanDalamProses',
        title: 'Dashb. Petugas',
        type: 'item',
        icon: <Icon.Home size={20} />,
        permissions: ['manager', 'verifikator'],
        navLink: '/dashboard_petugas',
    },
    // {
    //   type: "groupHeader",
    //   groupTitle: "PENGELOLA",
    //   permissions: ["public"],
    // },
    {
        id: 'terbitan',
        title: 'Terbitan',
        type: 'item',
        icon: <Icon.Clipboard size={20} />,
        permissions: ['public'],
        navLink: '/terbitan',
    },
    {
        id: 'pesan',
        title: 'Pesan',
        type: 'item',
        icon: <Icon.Mail size={20} />,
        permissions: ['public'],
        navLink: '/pesan',
    },

    {
        id: 'profile_pengelola',
        title: 'Profile',
        type: 'item',
        icon: <Icon.User size={20} />,
        navLink: '/profile_pengelola',
        permissions: ['public'],
    },

    {
        id: 'verifikasi_terbitan',
        title: 'Verif. Terbitan',
        type: 'item',
        icon: <Icon.CheckSquare size={20} />,
        navLink: '/verif_terbitan/list',
        // filterBase: "/verifikasi/list",
        permissions: ['manager', 'verifikator'],
    },
    // {
    //   id: "verifikasi",
    //   title: "Verif. Pengelola",
    //   type: "item",
    //   icon: <Icon.CheckCircle size={20} />,
    //   navLink: "/verif_pengelola/list",
    //   permissions: ["manager", "verifikator"]
    // },
    {
        id: 'balas_pesan',
        title: 'Pesan',
        type: 'item',
        icon: <Icon.Mail size={20} />,
        navLink: '/pesanInternal',
        permissions: ['manager', 'verifikator'],
    },
    {
        id: 'notifikasi',
        title: 'Notifikasi',
        type: 'item',
        icon: <Icon.AlertOctagon size={20} />,
        permissions: ['manager', 'verifikator', 'public'],
        navLink: '/notifikasi/:component/:param',
        filterBase: '/notifikasi/list/data',
    },

    // {
    //   id: "profile",
    //   title: "Profile",
    //   type: "item",
    //   icon: <Icon.User size={20} />,
    //   navLink: "/profile",
    //   permissions: ["manager", "verifikator"]
    // },
    {
        id: 'bagi_tugas',
        title: 'Pembagian Tugas',
        type: 'item',
        icon: <Icon.Server size={20} />,
        navLink: '/bagi_tugas',
        permissions: ['manager'],
    },
    {
        id: 'laporan',
        title: 'Laporan Terbitan',
        type: 'item',
        icon: <Icon.File size={20} />,
        navLink: '/laporan',
        permissions: ['manager', 'verifikator'],
    },
    {
        id: 'laporan_proses',
        title: 'Laporan Proses Terbitan',
        type: 'item',
        icon: <Icon.Filter size={20} />,
        navLink: '/laporan_proses',
        permissions: ['manager'],
    },
    {
        id: 'laporan_bulanan',
        title: 'Laporan Bulanan',
        type: 'item',
        icon: <Icon.Filter size={20} />,
        navLink: '/laporan_bulanan',
        permissions: ['manager'],
    },
    {
        id: 'laporan_tahunan',
        title: 'Laporan Tahunan',
        type: 'item',
        icon: <Icon.Filter size={20} />,
        navLink: '/laporan_tahunan',
        permissions: ['manager'],
    },
    // {
    //   id: "transaksi_issn",
    //   title: "Transaksi ISSN",
    //   type: "item",
    //   icon: <Icon.FileText size={20} />,
    //   navLink: "/transaksi_issn",
    //   permissions: ["manager", "verifikator"]
    // },
    // {
    //   id: "master_petugas",
    //   title: "Master Petugas",
    //   type: "item",
    //   icon: <Icon.User size={20} />,
    //   navLink: "/master_petugas",
    //   permissions: ["manager"]
    // },
    {
        id: 'master_pengelola',
        title: 'Master Pengelola',
        type: 'item',
        icon: <Icon.Users size={20} />,
        navLink: '/master_pengelola',
        permissions: ['manager', 'verifikator'],
    },
    // {
    //   id: "master_terbitan",
    //   title: "Master Terbitan",
    //   type: "item",
    //   icon: <Icon.Briefcase size={20} />,
    //   navLink: "/master_terbitan",
    //   permissions: ["manager", "verifikator"]
    // },
    {
        id: 'master_kategori',
        title: 'Master Kategori',
        type: 'item',
        icon: <Icon.File size={20} />,
        navLink: '/master_kategori',
        permissions: ['manager', 'verifikator'],
    },
    {
        id: 'master_pengumuman',
        title: 'Master Pengumuman',
        type: 'item',
        icon: <Icon.AlertTriangle size={20} />,
        navLink: '/master_pengumuman',
        permissions: ['manager'],
    },
    // {
    //   id: "master_pembayaran",
    //   title: "Master Pembayaran",
    //   type: "item",
    //   icon: <Icon.DollarSign size={20} />,
    //   navLink: "/master_pembayaran",
    //   permissions: ["manager", "verifikator"]
    // },
    // {
    //   id: "master_kegiatan",
    //   title: "Master Kegiatan",
    //   type: "item",
    //   icon: <Icon.Activity size={20} />,
    //   navLink: "/master_kegiatan",
    //   permissions: ["manager", "verifikator"]
    // },

    {
        id: 'master_issn',
        title: 'Master ISSN',
        type: 'item',
        icon: <Icon.Flag size={20} />,
        navLink: '/master_issn',
        permissions: ['manager'],
    },
    {
        id: 'setting',
        title: 'Setting',
        type: 'item',
        icon: <Icon.Settings size={20} />,
        navLink: '/setting',
        permissions: ['manager'],
    },
];

export default navigationConfig;
