import { removeCookies, getRole, getBearer } from '../../../utility/cookies/securing';
import { gotoURL } from '../../../utility/component/gotoUrl';
import { PostAPI } from '../../globalCallAPI';

export const LogoutAplikasi = async () => {
    var bearer = getBearer('sess');
    // var loginURL = '/login';
    // var roleAPI = 'publik';
    let s = await checkRole();
    if (bearer !== '') {
        // const role = await getRole('sess');
        // if (role === 'manager' || role === 'verifikator') {
        // loginURL = '/loginInternal';
        // roleAPI = 'admin';
        // }
        await fecthLogout(bearer, s.roleAPI, s.loginurl);
    } else {
        logoutNow(s.loginurl);
    }
};

const checkRole = async () => {
    let loginURL = '/login';
    let roleAPI = 'publik';
    const role = await getRole('sess');
    if (role === 'manager') {
        loginURL = '/loginInternal';
        roleAPI = 'admin';
    } else if (role === 'verifikator') {
        loginURL = '/loginInternal';
        roleAPI = role;
    }
    const r = { loginurl: loginURL, roleAPI: roleAPI };
    return r;
};

const logoutNow = (url: string) => {
    removeCookies();
    gotoURL(url);
};

let fecthLogout = async (bearer: string, role: string, loginURL: string) => {
    let ret = await PostAPI(`${role}/auth/logout`, {}, bearer);
    if (ret.code === '01' && ret.message === 'Token sudah tidak berlaku') {
        logoutNow(loginURL);
    } else {
        logoutNow(loginURL);
    }
    return null;
};
