import { createBrowserHistory } from 'history';
export let history = createBrowserHistory({ basename: '' });
export const gotoURL = (queryString: string) => {
    history.push(queryString);
};

export const UrlOld = () => {
    const location = history.location;
    return location;
};
