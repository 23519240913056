import Axios, { Canceler } from 'axios';
import { ReturnJSONType } from '../utility/parserJSON';
import { apiHeaders, baseApiUrl } from './settingAPI';

export const GetAPIWithData = async (apiURL: string, param: any, bearer?: string, usingOptional?: boolean) => {
    apiHeaders['accept'] = '*/*';

    let ret: any;
    let apiUrlGet = baseApiUrl + apiURL;
    const apiGetHeader = apiHeaders;
    if (bearer !== '') {
        apiGetHeader.Authorization = `Bearer ${bearer}`;
    }
    if (usingOptional) {
        apiUrlGet = apiUrlGet + param;
        param = '';
    }
    await Axios.get(apiUrlGet, { headers: apiGetHeader, params: param, responseType: 'blob' })
        .then((response) => {
            ret = response.data;
        })
        .catch((error) => {
            if (error.response) {
                ret = error.response.data;
            } else {
                ret = {
                    message: 'Terjadi Error : ' + error.message,
                    code: '01',
                    result: null,
                    status: 'Gagal',
                };
            }
        });
    return ret;
};
export let cancelGetAPI: Canceler;

export const GetAPI = async (apiURL: string, param: any, bearer?: string, usingOptional?: boolean) => {
    const CancelToken = Axios.CancelToken;
    apiHeaders['Content-Type'] = 'application/json';
    // console.log(bearer);
    let ret: ReturnJSONType = { code: '', status: '', message: '', result: null };
    let apiUrlGet = baseApiUrl + apiURL;
    const apiGetHeader = apiHeaders;
    if (bearer !== '') {
        apiGetHeader.Authorization = `Bearer ${bearer}`;
    }
    if (usingOptional) {
        apiUrlGet = apiUrlGet + param;
        param = '';
    }
    await Axios.get(apiUrlGet, {
        headers: apiGetHeader,
        params: param,
        cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancelGetAPI = c;
        }),
    })
        .then((response) => {
            ret = response.data;
        })
        .catch((error) => {
            if (error.response) {
                ret = error.response.data;
            } else {
                ret = {
                    message: 'Terjadi Error : ' + error.message,
                    code: '01',
                    result: null,
                    status: 'Gagal',
                };
            }
        });
    return ret;
};

export const PutAPI = async (apiURL: string, param: any, bearer?: string, usingOptional?: boolean) => {
    const CancelToken = Axios.CancelToken;
    apiHeaders['Content-Type'] = 'application/json';
    apiHeaders['accept'] = 'application/json';
    // console.log(bearer);
    let ret: ReturnJSONType = { code: '', status: '', message: '', result: null };
    let apiUrlGet = baseApiUrl + apiURL;
    const apiGetHeader = apiHeaders;
    if (bearer !== '') {
        apiGetHeader.Authorization = `Bearer ${bearer}`;
    }
    if (usingOptional) {
        apiUrlGet = apiUrlGet + param;
        param = '';
    }

    const data = JSON.stringify({ param });

    await Axios.put(apiUrlGet, data, {
        headers: apiGetHeader,
        cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancelGetAPI = c;
        }),
    })
        .then((response) => {
            ret = response.data;
        })
        .catch((error) => {
            if (error.response) {
                ret = error.response.data;
            } else {
                ret = {
                    message: 'Terjadi Error : ' + error.message,
                    code: '01',
                    result: null,
                    status: 'Gagal',
                };
            }
        });
    return ret;
};

export const PostAPI = async (apiURL: string, param: any, bearer?: string, isUpload?: boolean) => {
    // console.log(bearer);
    apiHeaders['Content-Type'] = 'application/json';
    if (isUpload) {
        apiHeaders['Content-Type'] = 'multipart/form-data';
    }

    let ret: ReturnJSONType = { code: '', status: '', message: '', result: null };
    const apiPostHeader = apiHeaders;

    if (bearer !== '') {
        apiPostHeader.Authorization = `Bearer ${bearer}`;
    }
    const apiUrlPost = baseApiUrl + apiURL;
    await Axios.post(apiUrlPost, param, { headers: apiPostHeader })
        .then((response) => {
            ret = response.data;
        })
        .catch((error) => {
            if (error.response) {
                ret = error.response.data;
            } else {
                ret = {
                    message: 'Terjadi Error : ' + error.message,
                    code: '01',
                    result: null,
                    status: 'Gagal',
                };
            }
        });
    return ret;
};
