export const UrlNotif = (session: string, link_type_to: string, link_param_to: string) => {
    let r = '';

    if (session === 'public') {
        if (link_type_to === 'review-pengajuan-form') {
            r = `/pengajuan-form?param=${link_param_to}`;
        } else if (link_type_to === 'proses-form') {
            r = `/pengajuan-form?param=${link_param_to}`;
        } else if (link_type_to === 'pendaftaran-revisi-form') {
            r = `/pendaftaran-form?param=${link_param_to}`;
        } else if (link_type_to === 'publish-form') {
            r = `/publish?param=${link_param_to}`;
        } else if (link_type_to === 'pendaftaran-ditolak-form') {
            r = `/pengajuan-form?param=${link_param_to}`;
        }
    } else {
        if (link_type_to === 'review-pengajuan-form') {
            r = `/review-pengajuan-form?param=${link_param_to}`;
        } else if (link_type_to === 'proses-form') {
            r = `/proses-form?param=${link_param_to}`;
        } else if (link_type_to === 'pendaftaran-revisi-form') {
            r = `/proses-form?param=${link_param_to}`;
        } else if (link_type_to === 'publish-form') {
            r = `/publish?param=${link_param_to}`;
        } else if (link_type_to === 'pendaftaran-ditolak-form') {
            r = `/proses-form?param=${link_param_to}`;
        }
    }

    return r;
};

export const UrlStatusTerbitan = (session: string, status_terbitan: string, link_param_to: string) => {
    let r = '';

    if (session === 'public') {
        if (status_terbitan === 'Draft') {
            r = `/pendaftaran-form?param=${link_param_to}`;
        } else if (status_terbitan === 'Revisi') {
            r = `/pendaftaran-form?param=${link_param_to}`;
        } else if (status_terbitan === 'Diajukan') {
            r = `/pengajuan-form?param=${link_param_to}`;
        } else if (status_terbitan === 'Verifikasi Pendaftaran') {
            r = `/pengajuan-form?param=${link_param_to}`;
        } else if (status_terbitan === 'Penerbitan SK ISSN') {
            r = `/pengajuan-form?param=${link_param_to}`;
        } else if (status_terbitan === 'Publish') {
            r = `/pengajuan-form?param=${link_param_to}`;
        } else if (status_terbitan === 'Ditolak') {
            r = `/pengajuan-form?param=${link_param_to}`;
        }
    } else {
        if (status_terbitan === 'Review Form Pengajuan Terbitan') {
            r = `/review-pengajuan-form?param=${link_param_to}`;
        } else if (status_terbitan === 'Verifikasi Pendaftaran') {
            r = `/proses-form?param=${link_param_to}`;
        } else if (status_terbitan === 'Penerbitan SK ISSN') {
            r = `/terbit-sk-form?param=${link_param_to}`;
        } else if (status_terbitan === 'Publish') {
            r = `/publish-form?param=${link_param_to}`;
        }
    }

    return r;
};
