import React from 'react';
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, Media, Badge } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
// import * as Icon from 'react-feather';
import { checkCookie, getBearer, getEmail, getRole } from '../../../utility/cookies/securing';
import { LogoutAplikasi } from '../../../api/admin/auth/logout';
import { fetchListNotifikasi, fetchListPesanOnly, fetchNotifStatusBar } from '../../../api/admin/pengelola/pesan';
import { DataNotifikasi, DataPesanGeneral, ListProcessNotifikasiStatusBar, ListProcessPesanStatusBar } from '../../../utility/interface/PesanIntefaces';
import { AlertTriangle, Bell, MessageSquare, Power, AlertOctagon } from 'react-feather';
import { getText } from '../../../utility/helper';
import { UrlNotif } from '../../../utility/component/GenerateLandingUrl';
import { withRouter } from 'react-router';
const UserDropdown = (props: any) => {
    // if (checkCookie('sess')) {
    //     // removeCookies('sess');
    //     history.push('/login');
    // }
    // props.checkLoginJWT();
    // const { logout, isAuthenticated } = useAuth0();
    return (
        <DropdownMenu right>
            {/* <DropdownItem tag="a" href="#" onClick={(e) => handleNavigation(e, '/pages/profile')}>
                <Icon.User size={14} className="mr-50" />
                <span className="align-middle">Edit Profile</span>
            </DropdownItem>
            <DropdownItem tag="a" href="#" onClick={(e) => handleNavigation(e, '/email/inbox')}>
                <Icon.Mail size={14} className="mr-50" />
                <span className="align-middle">My Inbox</span>
            </DropdownItem>
            <DropdownItem tag="a" href="#" onClick={(e) => handleNavigation(e, '/chat')}>
                <Icon.MessageSquare size={14} className="mr-50" />
                <span className="align-middle">Chats</span>
            </DropdownItem>
            <DropdownItem divider /> */}
            <DropdownItem
                tag="a"
                onClick={async (e) => {
                    e.preventDefault();
                    await LogoutAplikasi();
                }}
            >
                <Power size={14} className="mr-50" />
                <span className="align-middle">Log Out</span>
            </DropdownItem>
        </DropdownMenu>
    );
};

interface Navbar {
    langDropdown: boolean;
    notif_belum_dibaca: number;
    pesan_belum_dibaca: number;
    data: DataNotifikasi[];
    dataTable: ListProcessNotifikasiStatusBar[];
    dataPesan: DataPesanGeneral[];
    dataTablePesan: ListProcessPesanStatusBar[];
}

class NavbarUser extends React.Component<any, any> {
    state: Navbar = {
        langDropdown: false,
        notif_belum_dibaca: 0,
        pesan_belum_dibaca: 0,
        data: [],
        dataTable: [],
        dataPesan: [],
        dataTablePesan: [],
    };
    role = getRole('sess');
    bearer = getBearer('sess');

    componentDidMount() {
        if (!checkCookie('sess')) {
            // removeCookies('sess');
            LogoutAplikasi();
        }
        this.autoloadFunc();
    }

    componentDidUpdate = async (prevProps: any) => {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            await this.loadCountNotifPesan();
            //     this.fetchData(this.props.userID);
        }
        // console.log('prevProps');
        // console.log(prevProps.location.pathname);
        // console.log(this.props.location.pathname);
    };

    autoloadFunc = async () => {
        await this.loadCountNotifPesan();
    };

    loadCountNotifPesan = async () => {
        if (this.bearer !== '') {
            let ret = await fetchNotifStatusBar(this.bearer);
            if (ret.code === '01' && ret.message === 'Token sudah tidak berlaku') {
                await LogoutAplikasi();
            } else {
                if (ret.result) {
                    this.setState(ret.result);
                }
            }
        }
    };

    LoadTab = async (page: number = 0, search: string = '') => {
        if (this.bearer !== '') {
            let ret = await fetchListNotifikasi(this.bearer, page, search);
            if (ret.code === '01' && ret.message === 'Token sudah tidak berlaku') {
                await LogoutAplikasi();
            } else {
                if (ret.result.data) {
                    // this.setState({ data: ret.result.data });
                    await this.generateListDataTable(ret.result.data);
                    await this.loadCountNotifPesan();
                }
            }
        }
    };

    generateListDataTable = async (data: DataNotifikasi[]) => {
        var dataGenarate: ListProcessNotifikasiStatusBar[] = [];
        data.map((v: DataNotifikasi, key) => {
            let base_color = 'light';
            if (v.status !== 'Sudah Dibaca') {
                base_color = 'primary';
            }
            dataGenarate.push({
                isi_notif: (
                    <div key={key} className="d-flex justify-content-between">
                        <Media className="d-flex align-items-start">
                            <Media left>
                                <AlertTriangle className={`font-medium-5 ${base_color}`} size={21} />
                            </Media>
                            <Media body href={UrlNotif(this.role, v.link_type_to, v.link_param_to)}>
                                <Media heading className={`media-heading ${base_color}`} tag="h6">
                                    {v.tanggal}
                                </Media>
                                <p className="notification-text">{v.isi_notif}</p>
                            </Media>
                            {/* <small>
                                <time className="media-meta" dateTime="2015-06-11T18:29:20+08:00">
                                    9 hours ago
                                </time>
                            </small> */}
                        </Media>
                    </div>
                ),
            });
            return null;
        });

        // console.log(dataGenarate);

        this.setState({ dataTable: dataGenarate });
    };

    generateLinkPesan = () => {
        let url_ = '/pesan';
        if (this.role !== 'public') {
            url_ = '/pesanInternal';
        }
        return url_;
    };

    generateListDataTablePesan = async (data: DataPesanGeneral[]) => {
        const pesan_length = 50;

        let url_pesan = '/detail_pesan?param=';
        if (this.role !== 'public') {
            url_pesan = '/detail_pesan_internal?param=';
        }
        var dataGenarate: ListProcessPesanStatusBar[] = [];
        data.map((v: DataPesanGeneral, key) => {
            let base_color = 'light';
            if (v.jml_belum_terbaca > 0) {
                base_color = 'primary';
            }

            let name = <></>;
            if (this.role !== 'public') {
                name = (
                    <>
                        <Media heading className={`${base_color} media-heading mb-0`} tag="h6">
                            {v.pengelola.nama_pic} {'   '}
                            <Badge pill color="primary">
                                {v.jml_belum_terbaca}
                            </Badge>
                        </Media>
                        <p className="mt-0 mb-0 notification-text">{v.pengelola.email}</p>
                        <p className="notification-text">{v.pengelola.nama_instansi}</p>
                    </>
                );
            } else {
                name = (
                    <>
                        <Media heading className={`${base_color} media-heading mb-0`} tag="h6">
                            {`${v.petugas.nama}`}
                        </Media>
                        <p className="mt-0 mb-0 notification-text">Petugas</p>
                    </>
                );
            }

            let isi_pesan = '';
            if (v.last_pesan_tanggapan.isi_pesan.length > pesan_length) {
                let isi_pesan_raw = getText(v.last_pesan_tanggapan.isi_pesan);
                let isi_pesan_substr = isi_pesan_raw.substring(0, pesan_length);
                isi_pesan = `${isi_pesan_substr} ...`;
            } else {
                isi_pesan = `${getText(v.last_pesan_tanggapan.isi_pesan)}`;
            }

            dataGenarate.push({
                isi_pesan: (
                    <div key={key} className="d-flex justify-content-between">
                        <Media className="d-flex align-items-start">
                            <Media left>
                                <AlertOctagon className={`font-medium-5 ${base_color}`} size={21} />
                            </Media>
                            <Media body href={`${url_pesan}${v.id_pesan}`}>
                                {name}
                                <hr className="mt-0 mb-0" />
                                <p className="notification-text mb-0">
                                    <strong>Subject</strong> : {v.isi_pesan}
                                </p>
                                <hr className="mt-0 mb-0" />
                                <p className="notification-text mb-0">
                                    <strong>Pesan Terakhir </strong> : {isi_pesan}
                                </p>
                                {/* <p className="notification-text" dangerouslySetInnerHTML={{ __html: v.last_pesan_tanggapan.isi_pesan }}></p> */}
                                <hr className="mt-0 mb-05" />
                                <small className={base_color}>{v.tanggal}</small>
                            </Media>
                        </Media>
                    </div>
                ),
            });
            return null;
        });

        // console.log(dataGenarate);

        this.setState({ dataTablePesan: dataGenarate });
    };

    // ========== PESAN ===============
    loadTablePesan = async (page: number = 0, search: string = '') => {
        if (this.bearer !== '') {
            let ret = await fetchListPesanOnly(this.bearer, page, search);
            if (ret.code === '01' && ret.message === 'Token sudah tidak berlaku') {
                await LogoutAplikasi();
            } else {
                if (ret.result.data) {
                    // this.setState({ data: ret.result.data });
                    await this.generateListDataTablePesan(ret.result.data);
                    await this.loadCountNotifPesan();
                }
            }
        }
    };

    render() {
        var email = getEmail('sess');
        return (
            <ul className="nav navbar-nav navbar-nav-user float-right">
                <UncontrolledDropdown tag="li" className="dropdown-notification nav-item">
                    <DropdownToggle
                        tag="a"
                        className="nav-link nav-link-label"
                        onClick={() => {
                            this.loadTablePesan();
                        }}
                    >
                        <MessageSquare size={21} />
                        <Badge pill color="primary" className="badge-up">
                            {' '}
                            {this.state.pesan_belum_dibaca}{' '}
                        </Badge>
                    </DropdownToggle>
                    <DropdownMenu tag="ul" right className="dropdown-menu-media">
                        <li className="dropdown-menu-header">
                            <div className="dropdown-header mt-0">
                                <h3 className="text-white">{this.state.pesan_belum_dibaca} New</h3>
                                <span className="notification-title">Pesan</span>
                            </div>
                        </li>
                        <PerfectScrollbar
                            className="media-list overflow-hidden position-relative"
                            options={{
                                wheelPropagation: false,
                            }}
                        >
                            {this.state.dataTablePesan.map((d, k) => {
                                return d.isi_pesan;
                            })}
                        </PerfectScrollbar>
                        <li className="dropdown-menu-footer">
                            <DropdownItem tag="a" href={this.generateLinkPesan()} className="p-1 text-center">
                                <span className="align-middle">Lihat semua Pesan</span>
                            </DropdownItem>
                        </li>
                    </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown tag="li" className="dropdown-notification nav-item">
                    <DropdownToggle
                        tag="a"
                        className="nav-link nav-link-label"
                        onClick={() => {
                            this.LoadTab();
                        }}
                    >
                        <Bell size={21} />
                        <Badge pill color="primary" className="badge-up">
                            {' '}
                            {this.state.notif_belum_dibaca}{' '}
                        </Badge>
                    </DropdownToggle>
                    <DropdownMenu tag="ul" right className="dropdown-menu-media">
                        <li className="dropdown-menu-header">
                            <div className="dropdown-header mt-0">
                                <h3 className="text-white">{this.state.notif_belum_dibaca} New</h3>
                                <span className="notification-title">Notifikasi</span>
                            </div>
                        </li>
                        <PerfectScrollbar
                            className="media-list overflow-hidden position-relative"
                            options={{
                                wheelPropagation: false,
                            }}
                        >
                            {this.state.dataTable.map((d, k) => {
                                return d.isi_notif;
                            })}
                        </PerfectScrollbar>
                        <li className="dropdown-menu-footer">
                            <DropdownItem tag="a" href={'/notifikasi/list/data'} className="p-1 text-center">
                                <span className="align-middle">Lihat semua Notifikasi</span>
                            </DropdownItem>
                        </li>
                    </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
                    <DropdownToggle tag="a" className="nav-link dropdown-user-link">
                        <div className="user-nav" style={{ marginTop: 7 }}>
                            <span className="user-name text-bold-600">{email}</span>
                            <span className="user-status">{this.role}</span>
                        </div>
                    </DropdownToggle>
                    <UserDropdown {...this.props} />
                </UncontrolledDropdown>
            </ul>
        );
    }
}
export default withRouter(NavbarUser);
